.processing-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050; /* Ensure it's on top of other content */
  }
  
  .processing-indicator p {
    margin-top: 10px; /* Adds space between spinner and text */
    font-size: 16px; /* Adjust the font size as needed */
  }