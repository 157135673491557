/* LoadingSpinner.css */
.loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
}

.spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 2px solid #000;
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
